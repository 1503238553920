import { useMemo } from 'react';

import {
  ProductCardContext,
  ProductCardContextType,
} from '~/src/common/components/GridCard/ProductCard/ProductCardContext';
import { useGetProductCartFreeArticleCoupons } from '~/src/common/hooks/cart/useGetCartFreeArticleCoupons';
import { useGetCartProductQuantity } from '~/src/common/hooks/cart/useGetCartProductQuantity';
import {
  UpdateCartProductQuantityPayload,
  useUpdateCartProductQuantity,
} from '~/src/common/hooks/cart/useUpdateCartProductQuantity';
import { useDelivery } from '~/src/common/hooks/useDelivery';
import { useStable } from '~/src/common/hooks/useStable';
import { useHandleFirstAddToCartError } from '~/src/common/services/error-handler/error-handler';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker, {
  ExtraCartEventProperties,
  getCartEventMapping,
  getCartEventName,
} from '~/src/common/services/Tracker';
import { Cart } from '~/src/common/typings/cart';
import { useGetCart } from '~/src/queries/api-ecom/generated/api-ecom';
import { AddProductBodyProductAddToCartSource } from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { CartRelatedProduct, Product, RelatedProduct } from '~/src/typings/products/types';

import * as S from '../layout';

import AvailableProductCard from './AvailableProductCard/AvailableProductCard';
import UnavailableProductCard from './UnavailableProductCard/UnavailableProductCard';

interface Props {
  product: Product | RelatedProduct | CartRelatedProduct;
  eventSource: AddProductBodyProductAddToCartSource;
  categoryName?: string;
  subcategory?: { id: string; name: string };
  onRefresh?: () => void;
  onProductCardClick?: () => void;
  analyticsProperties?: ExtraCartEventProperties;
}

const ProductCard = ({
  product,
  eventSource,
  categoryName,
  subcategory,
  onRefresh,
  onProductCardClick,
  analyticsProperties,
}: Props) => {
  const { data: cart } = useGetCart<Cart>();
  const cartQuantity = useGetCartProductQuantity({ sku: product.sku, id: product.id });
  const freeQuantity = useGetProductCartFreeArticleCoupons(product.id);

  const { availableQuantity } = product;

  const params: UpdateCartProductQuantityPayload = {
    product,
    productEventSource: eventSource,
    categoryName,
    subcategoryName: subcategory?.name,
    onRefresh,
  };

  const { mutate: updateQuantity } = useUpdateCartProductQuantity(params);
  const delivery = useDelivery();
  const isDeliverySetup = delivery.isSetup && delivery.isTimeSlotValid;
  const deliveryAddressModal = useNiceModal('delivery-address-modal');
  const deliverySelectSlotModal = useNiceModal('delivery-select-slot-modal');

  const productCardContextValue: ProductCardContextType = useMemo(() => {
    return {
      product,
      analyticsData: {
        categoryName,
        subcategoryName: subcategory?.name,
        eventSource,
        otherAnalyticsProperties: analyticsProperties,
      },
      onUpdateQuantity: (quantity: number) => {
        updateQuantity({
          data: {
            product: {
              id: product.id,
              quantity,
              categoryId: subcategory?.id,
              marketingPosition: analyticsProperties?.['product position in the list'],
            },
          },
        });
      },
    };
  }, [product, categoryName, subcategory, eventSource, updateQuantity, analyticsProperties]);

  const isProductAvailable = availableQuantity !== 0;

  const handleDisabledPickerClick = useStable(async (quantity: number, increment: number) => {
    const { eventName } = getCartEventName(quantity, quantity + increment);

    Tracker.sendEvent(
      eventName,
      getCartEventMapping({
        cart,
        quantity: increment,
        productEventSource: eventSource,
        product,
        status: delivery.address ? 'need slot' : 'need shipping',
        otherAnalyticsProperties: analyticsProperties,
      }),
    );

    if (delivery.address) {
      await deliverySelectSlotModal.show();
    } else {
      await deliveryAddressModal.show();
    }

    updateQuantity(
      {
        data: {
          product: {
            id: product.id,
            quantity: quantity + increment,
            categoryId: subcategory?.id,
          },
        },
      },
      { onError: useHandleFirstAddToCartError },
    );
  });

  return (
    <S.Container onClick={onProductCardClick}>
      <ProductCardContext.Provider value={productCardContextValue}>
        {isProductAvailable ? (
          <AvailableProductCard
            product={product}
            categoryName={categoryName}
            subcategoryName={subcategory?.name}
            isDeliverySetup={isDeliverySetup}
            cartQuantity={cartQuantity}
            onDisabledPickerClick={(quantity, increment) =>
              void handleDisabledPickerClick(quantity, increment)
            }
            eventSource={eventSource}
            freeQuantity={freeQuantity}
          />
        ) : (
          <UnavailableProductCard
            product={product}
            categoryName={categoryName}
            subcategoryName={subcategory?.name}
            eventSource={eventSource}
          />
        )}
      </ProductCardContext.Provider>
    </S.Container>
  );
};

export default ProductCard;
